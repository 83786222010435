import React, {useState} from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import MoreInfoButton from "../commonV3/MoreInfoButton";
//import BuyButton from "../commonV3/BuyButton";
import {StringParam, withQueryParams} from "use-query-params";
import Shipping from "./Shipping";
import IShipping from "../../images/svgs/EnviosDEF.svg";
import ITrial from "../../images/svgs/Trial.svg";
import ITrust from "../../images/svgs/Confianza_54px_Q.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TrialButton from "../commonV3/TrialButton";


const Header = ({intl, location, query, title, description}) => {
    const [nav3, setNav3] = useState();
    const [nav4, setNav4] = useState()

    const sliderSettings = {
        dots: true,
        lazyLoad: "progressive",
        infinite: false,
        speed: 500,
        arrows: false,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 2000,
        cssEase: "ease-in",
        
        dotsClass: "slick-dots slick-thumb",
        customPaging: function (i) {
            return <div className="dot"></div>;
        },
    }

    const sliderDotsSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        dotsClass: "slick-dots slick-thumb",
        customPaging: function (i) {
            return (
                <div className="dot"></div>
            );
        },
    }

    const {purchase_method, userId, promotion_code} = query;
    const images = useStaticQuery(graphql`
    query {
        AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "sliderhomeoneimage" }
          extension: { eq: "jpg" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                originalName
                src
                srcSet
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      },
      AllPostImagesMobile: allFile(
        filter: {
          relativeDirectory: { eq: "sliderhomeoneimagemobile" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                originalName
                src
                srcSet
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      },
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "reloj-teleasistencia-durcal" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
//                   {intl.formatMessage({id: "homeV3HeaderTitle"})}
    return (
        <HeaderSectionWrapper>
            <DescriptionWrapperMobile>
                <h1> {title}</h1>
                <span>
                    {description}
                </span>
            </DescriptionWrapperMobile>
            <SliderWrapper>
                <Slider {...sliderSettings}  asNavFor={nav4} ref={(slider3) => setNav3(slider3)}>
                    {images.AllPostImages.edges.map(image => (
                        <ImageWrapper key={image.key}>
                            <StyledImage fluid={image.node.childImageSharp.fluid} />
                        </ImageWrapper>
                    ))}
                </Slider>
            </SliderWrapper>
            <SliderWrapperM>
                <Slider {...sliderSettings}  asNavFor={nav4} ref={(slider3) => setNav3(slider3)}>
                    {images.AllPostImagesMobile.edges.map(image => (
                        <ImageWrapper key={image.key}>
                            <StyledImage fluid={image.node.childImageSharp.fluid} />
                        </ImageWrapper>
                    ))}
                </Slider>
            </SliderWrapperM>

            <DescriptionWrapper>
                <h1>{title}</h1>
                <span>
                    {description}
                </span>

                <PriceWrapper>
                    <span>

                    </span>
                    <Price></Price>
                    <div className="buttonsDiv">
                        <TrialButton
                            location={location}
                            intl={intl}
                            type="whiteopacity"
                        >
                            <ButtonText>{intl.formatMessage({id: "tryFree"})}</ButtonText>
                        </TrialButton>
                        <MoreInfoButton
                            location={location}
                            intl={intl}
                            type="primary"
                            eventType="buy"
                        >
                            <ButtonText>{intl.formatMessage({id: "buyButton"})}</ButtonText>
                        </MoreInfoButton>
                    </div>
                </PriceWrapper>
            </DescriptionWrapper>
            <div className="TrialButton">
                <TrialButton
                    location={location}
                    intl={intl}
                    type="primary"
                >
                    <ButtonText>{intl.formatMessage({id: "tryFree"})}</ButtonText>
                </TrialButton>
            </div>
            <div className="ShipTrial" >
                <Shipping {...{title:intl.formatMessage({id: "homeV3ShippingTitle"}), description: intl.formatMessage({id: "homeV3ShippingDescription"}),icon: <IShipping/>}}/>
                <Shipping {...{title:intl.formatMessage({id: "homeV3TrialTitle"}), description: intl.formatMessage({id: "homeV3TrialDescription"}),icon: <ITrial/>}}/>
                <Shipping {...{title:intl.formatMessage({id: "homeV3TrustTitle"}), description: intl.formatMessage({id: "homeV3TrustDescription"}),icon: <ITrust/>}}/>
            </div>
        </HeaderSectionWrapper>
    )
}


const HeaderSectionWrapper = styled.div`
  text-align: center;
  
  align-items: center;
  
  .ShipTrial {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top:32px;
    @media (min-width: ${props => props.theme.screen.xl}) {
      flex-direction: row;
      padding: 24px 64px;
    }
  }
  
  .TrialButton {
    padding: 24px 16px;
    @media (min-width: ${props => props.theme.screen.xl}) {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row;
    justify-content: space-between;
   /* .ShipTrial {
      display: none;
    }*/
  }
`

const DescriptionWrapper = styled.div`
  display: none;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  
  h1 {
    ${({theme}) => theme.font_size.xlarge};
    font-family: ${({theme}) => theme.font.bold};
    margin-bottom: 0;
    white-space: pre;
  }
  
  span {
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.medium};
  }
  
  .ShipTrial {
    display: none;
  } 
  
   @media (min-width: ${props => props.theme.screen.xl}) {  
    display: flex;
    position: absolute;  
    top: 275px;  
    width: 50%;
    align-items: baseline;  
    margin-left: 64px;  
    text-align: left;
    color: #FFF;
     h1 {
       ${({ theme }) => theme.font_size.xxxlarge};
     }
     span {
       ${({ theme }) => theme.font_size.medium};
     }
     .ShipTrial {
       display: flex;
       flex-direction: row;
       gap: 16px;
       width: 100%;
       margin-top:57px;
       max-width: 600px;
     }
   }
`

const DescriptionWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-bottom: 18px;
  
  h1 {
    ${({theme}) => theme.font_size.xlarge};
    font-family: ${({theme}) => theme.font.bold};
    margin-bottom: 0;
    white-space: pre;
  }
  
  span {
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.medium};
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  display: block !important;
  margin-left: auto;
  margin-right: auto;
`

const StyledImage = styled(Img)`
  //width: 100%;
  display: block;
`

const PriceWrapper = styled.div`
  display: none;
  width: 100%;
  padding-top: 40px;
  gap:8px;
  .buttonsDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }

  @media (min-width: ${props => props.theme.screen.xl}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 600px;
  }
`
const Price = styled.div`
    align-items: center;
    text-align: left;
    width: 100%;
    ${({ theme }) => theme.font_size.xxlarge};
    font-family: ${({ theme }) => theme.font.extraBold};
`
const PriceAnnual = styled.span`
    width: 100%;
    ${({ theme }) => theme.font_size.regular};
    font-family: ${({ theme }) => theme.font.medium};
`

const ButtonText = styled.div`
    margin: 0;
    text-align: center;
    ${({theme}) => theme.font_size.regular};
    font-family: ${({theme}) => theme.font.bold};
`

const SliderWrapper = styled.div`
  div {
    width: 100%;
  }
  display: none;
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: block;
  }
`

const SliderWrapperM = styled.div`
  div {
    width: 100%;
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: none;
  }
  
`

const SliderWrapperDots = styled.div`
  padding-bottom: 32px;
  .slick-dots {
    position: relative;
  }
  .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (min-width: ${props => props.theme.screen.xl}) {
      top: -120px;
      left: 450px;
    }
  }

  .slick-dots.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 16px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: ${({ theme }) => theme.color.neutral};
  }

  .slick-dots.slick-thumb li.slick-active {
    background-color: ${({ theme }) => theme.color.purple};
    @media (min-width: ${props => props.theme.screen.xl}) {
      background-color: ${({ theme }) => theme.color.white};
    }
    width: 35px;
  }
`




export default withQueryParams(
    {
        purchase_method: StringParam,
        userId: StringParam,
        promotion_code: StringParam,
    },
    injectIntl(Header),
)

